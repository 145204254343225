.border-none[data-v-12f2c0c7]:before {
  display: none;
}
.border-none[data-v-12f2c0c7]:after {
  display: none;
}
.flex-fix[data-v-12f2c0c7] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-12f2c0c7]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-12f2c0c7] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-12f2c0c7] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-12f2c0c7] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-12f2c0c7] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-12f2c0c7] {
  *zoom: 1;
}
.clear-fix[data-v-12f2c0c7]:before,
.clear-fix[data-v-12f2c0c7]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.Article[data-v-12f2c0c7] {
  background: #fff;
  height: 100vh;
  padding: 0.54rem 0.24rem;
}
.Article header[data-v-12f2c0c7] {
  font-size: 0.52rem;
  color: #333;
  margin-bottom: 0.46rem;
}
.Article article[data-v-12f2c0c7] {
  font-size: 0.3rem;
  color: #333;
  line-height: 0.42rem;
}
